<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('barc_config.fiscal_year') }}</b-th>
                              <b-td style="width:70%">{{ assessmentOther.fiscal_year }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.service_length_score_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('barc_config.point_per_year') }}</b-th>
                              <b-td style="width:70%">{{ assessmentOther.service_length.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.age_score_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('barc_config.age_range_from') }}</b-th>
                              <b-th>{{ $t('barc_config.age_range_to') }}</b-th>
                              <b-th>{{ $t('barc_config.point') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(age, index) in assessmentOther.age_score_info" :key="index">
                              <b-td>{{ index + 1 }}</b-td>
                              <b-td>{{ age.age_range_from }}</b-td>
                              <b-td>{{ age.age_range_to }}</b-td>
                              <b-td>{{ age.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.acr_report_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('barc_config.performance_evaluation_type') }}</b-th>
                              <b-th>{{ $t('barc_config.point') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(acr, index) in assessmentOther.acr_score_info" :key="index">
                              <b-td>{{ index + 1 }}</b-td>
                              <b-td>{{ getEvaluationType(acr.type_id) }}</b-td>
                              <b-td>{{ acr.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.publication_score_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('barc_config.author_type') }}</b-th>
                              <b-th>{{ $t('barc_config.point') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(publication, index) in assessmentOther.publication_score_info" :key="index">
                              <b-td>{{ index + 1 }}</b-td>
                              <b-td>{{ getAuthorType(publication.type_id) }}</b-td>
                              <b-td>{{ publication.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.scientific_technical_seminar_score_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('barc_config.author_type') }}</b-th>
                              <b-th>{{ $t('barc_config.point') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(scientific, index) in assessmentOther.scientific_score_info" :key="index">
                              <b-td>{{ index + 1 }}</b-td>
                              <b-td>{{ getAuthorType(scientific.type_id) }}</b-td>
                              <b-td>{{ scientific.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                              <h5 class='complain-title'>{{ $t('barc_config.training_score_info') }}</h5>
                            </b-col>
                          </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('barc_config.author_type') }}</b-th>
                              <b-th>{{ $t('barc_config.point') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(training, index) in assessmentOther.training_score_info" :key="index">
                              <b-td>{{ index + 1 }}</b-td>
                              <b-td>{{ getTrainingType(training.type_id) }}</b-td>
                              <b-td>{{ training.point }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.assessmentOther = this.item
  },
  data () {
    return {
      assessmentOther: [],
      slOffset: 1,
      acrTypes: [
        {
          value: 1,
          text: this.$t('barc_config.extra_ordinary')
        },
        {
          value: 2,
          text: this.$t('barc_config.very_good')
        },
        {
          value: 3,
          text: this.$t('barc_config.good')
        }
      ],
      authorTypes: [
        {
          value: 1,
          text: this.$t('barc_config.principal_type')
        },
        {
          value: 2,
          text: this.$t('barc_config.associate_type')
        }
      ],
      trainingTypes: [
        {
          value: 1,
          text: this.$t('barc_config.foundation_training')
        },
        {
          value: 2,
          text: this.$t('barc_config.others_training')
        }
      ]
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('barc_config.class_grade'), class: 'text-left' },
        { label: this.$t('barc_config.point'), class: 'text-left' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'cg_name_bn' },
          { key: 'point' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'cg_name' },
          { key: 'point' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getEvaluationType (type) {
      const acr = this.acrTypes.find(acr => acr.value === type)
      return acr.text
    },
    getAuthorType (type) {
      const author = this.authorTypes.find(author => author.value === type)
      return author.text
    },
    getTrainingType (type) {
      const training = this.trainingTypes.find(training => training.value === type)
      return training.text
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
