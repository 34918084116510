
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('barc_config.self_score_assessment_other') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('barc_config.fiscal_year')"
              label-for="fiscal_year_id"
            >
              <b-form-select
                plain
                v-model="search.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('barc_config.self_score_assessment_other_information') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link class="btn_add_new" to="/incentive-grant-service/barc/configuration/self-score-assessment-others-form" :class="'btn btn-primary'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Complain" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <router-link :to="'/incentive-grant-service/barc/configuration/self-score-assessment-others-form?id='+ data.item.id" title="Edit" :class="'btn_table_action table_action_edit'"><i class="ri-ball-pen-fill"></i></router-link>
                      <a href="javascript:" title="Status" class="btn_table_action" :class="data.item.status === 1 ? ' table_action_view' : '  table_action_toggle'" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('barc_config.self_score_assessment_other_information') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="item" :key="testId"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { selfScoreAssessmentOtherList, selfScoreAssessmentOtherStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0'
      },
      testId: 0,
      rows: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('barc_config.self_score_assessment_other') + ' ' + this.$t('globalTrans.entry') : this.$t('barc_config.self_score_assessment_other') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('barc_config.fiscal_year'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, selfScoreAssessmentOtherStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, selfScoreAssessmentOtherList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      return data.map(item => {
        const obj = this.$store.state.commonObj.fiscalYearList.find(year => year.value === item.fiscal_year_id)
        const fiscalYearlData = { fiscal_year: obj.text_en }
        return Object.assign({}, item, fiscalYearlData)
      })
    }
  }
}
</script>
